<template>
    <div class="page-wrapper" id="scroll_top">
        <section class="hero_content" v-if="pageIsLoaded">
            <div class="data">
                <h2 class="title small" data-inview="fadeInUp" data-delay="200">
                    {{ $t('Actualité') }}
                </h2>
                <h1 class="title medium" data-inview="fadeInUp" data-delay="300">
                    <span v-html="content.titreSimple"></span>
                </h1>
            </div>
        </section>

        <!-- Articles grid -->
        <section class="articles-grid" v-if="pageIsLoaded" data-inview="fadeInUp" data-delay="600">
            <div class="grid">
                <router-link
                    :to="item.page.jsonUrl"
                    class="article-thumb"
                    :title="item.page.title"
                    v-for="(item, i) in pageResults"
                    :key="i"
                >
                    <div class="img-wrap">
                        <img :src="item.page.imageSimple1.url" :alt="item.page.imageSimple1.titre" />
                    </div>
                    <div class="content">
                        <h2 class="regular-text title bold">{{ item.page.title }}</h2>
                        <p v-if="$route.meta.lang && $route.meta.lang == 'en'" class="small-text text">
                            {{ item.page.postDate_en }}
                        </p>
                        <p v-else class="small-text text">
                            {{ item.page.postDate }}
                        </p>
                    </div>
                </router-link>
            </div>
            <div
                class="pagination_wrap"
                data-inview="fadeInUp"
                data-delay="200"
                v-if="pager.total_pages > 1"
            >
                <a href="#" class="cta switch fade"
                    :class="{ 'is-active': pager.current_page !== 1 }"
                    :disabled="pager.current_page === 1"
                    @click.prevent="fetchPage(pager.current_page - 1)"
                >
                    <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                    <p class="regular-text">{{ $t('Page Précédente') }}</p>
                </a>
                <p class="regular-text">
                    {{ pager.current_page }} / {{ pager.total_pages }}
                </p>
                <a href="#" class="cta fade"
                    :class="{ 'is-active': pager.current_page < pager.total_pages }"
                    :disabled="pager.current_page === pager.total_pages"
                    @click.prevent="fetchPage(pager.current_page + 1)"
                >
                    <p class="regular-text">{{ $t('Page suivante') }}</p>
                    <img src="@/assets/img/arrow-right.svg" alt="arrow right" />
                </a>
            </div>

            <p class="regular-text" v-if="!isLoading && (!pageResults.length)">
                {{ $t('Aucun résultat pour cette recherche.') }}
            </p>

            <div class="page-loader" v-if="isLoading">
                <svg
                    class="simple-page-loader"
                    width="78"
                    viewBox="0 0 38 38"
                    xmlns="http://www.w3.org/2000/svg"
                    stroke="#596778"
                >
                    <g transform="translate(1 1)" stroke-width="1" fill="none" fill-rule="evenodd">
                        <circle stroke-opacity=".5" cx="18" cy="18" r="18" />
                        <path d="M36 18c0-9.94-8.06-18-18-18">
                            <animateTransform
                                attributeName="transform"
                                type="rotate"
                                from="0 18 18"
                                to="360 18 18"
                                dur="1s"
                                repeatCount="indefinite"
                            />
                        </path>
                    </g>
                </svg>
            </div>
        </section>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import GLightbox from 'glightbox'

import axios from 'axios'

import { initInViewAnimations } from '@/plugins/AnimationsLayer'

// import Flickity from 'flickity'

export default {
    data() {
        return {
            isPaginating: false,
            isFiltering: false,
            isLoading: false,
            currentPage: 1,
            category: 'all',
            paginateResults: [],
            filteringResults: [],
            pager: null,
        }
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        this.lightboxelement = GLightbox
                        GLightbox()

                        setTimeout(() => {
                            // this.teamCarousel.resize()
                        }, 1755)
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        pageResults() {
            if (!this.isPaginating && !this.isFiltering) {
                return this.globals.actualitesEntry.data
            }

            return this.isPaginating
                ? this.paginateResults
                : this.paginateResults
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        async fetchPage(pageNb) {

            if (pageNb < 1 || this.pager && pageNb > this.pager.total_pages) {
                return
            }

            this.isLoading = true

            // Scroll to top
            window.scrollTo(0, 0)

            const locale = this.$route.meta.lang === 'fr' ? '' : '/' + this.$route.meta.lang
            const baseUrl = '/api'

            await axios
                .get(
                    `${process.env.VUE_APP_SITE_BASE_URL + locale + baseUrl}/actualitesEntry.json?page=${pageNb}`
                )
                .then(res => {
                    this.pager = res.data.meta.pagination
                    this.currentPage = this.pager.current_page

                    this.paginateResults = res.data.data
                    this.isFiltering = false
                    this.isPaginating = true

                    this.isLoading = false

                    initInViewAnimations()

                    return `${process.env.VUE_APP_SITE_BASE_URL + locale + baseUrl}`
                })
                .catch(() => {})
        },
    },

    mounted() {
        // this.searchAndFilterResults('', '', '', 1, false)
        initInViewAnimations();

        this.fetchPage(1)
    },
}
</script>

<style lang="scss" scoped>


    .pagination_wrap {
        padding-top: 45px;

        @media screen and (max-width: 850px) {
            padding-top: 25px;
        }
    }

    .hero_content {
        height: auto;

        .data {
            padding: 5vw;
            padding-top: 8vw;
            padding-bottom: 0;
            margin-bottom: 0;

            @media screen and (max-width: 850px) {
                padding-top: 45px;
            }
        }
    }

    .page-loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 5;
        background: rgba(255, 255, 255, 0.59);

        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
